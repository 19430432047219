<template>
  <div class="flex">
    <div class="w-1/2">
      <label class="block mb-6">
        <span class="label">Línguas disponíveis</span>

        <ul class="rounded border border-gray-200 shadow">
          <li
            v-for="(language, index) in languages"
            :key="language.abbreviation"
            class="border-b border-gray-200 py-2 px-4 text-gray-800 font-bold flex justify-between items-center"
          >
            <div>{{ language.title }} - {{ language.abbreviation }}</div>
            <div class="button bg-red-600" @click.prevent="remove(index)">
              Excluir
            </div>
          </li>
        </ul>
      </label>
    </div>

    <div class="w-1/2 ml-4">
      <form @submit.prevent="addLanguage()" class="pl-4">
        <h3 class="font-bold mb-4">Adicionar nova lingua</h3>
        <label class="block mb-4">
          <span class="label">Título</span>
          <input
            type="text"
            class="input"
            v-model="newLangTitle"
            placeholder="Português"
          />
        </label>

        <label class="block mb-4">
          <span class="label">Sigla</span>
          <input
            type="text"
            class="input"
            v-model="newLangAbbrev"
            placeholder="pt"
          />
        </label>

        <AppButton :loading="loadingLanguage">Adicionar</AppButton>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AppButton from "@/components/AppButton";

export default {
  name: "Settings",
  components: {
    AppButton
  },
  filters: {},
  props: {},
  data: function() {
    return {
      loadingLanguage: false,
      newLangTitle: "",
      newLangAbbrev: ""
    };
  },
  computed: {
    ...mapState({
      languages: state => state.Settings.languages,
      maintenance: state => state.Settings.maintenance
    })
    // ...mapGetters({
    //   item: "namespace/item"
    // })
  },
  watch: {},
  async created() {
    await this.$store.dispatch("Settings/list");
  },
  async mounted() {},
  methods: {
    async addLanguage() {
      const languages = [...this.languages];
      languages.push({
        abbreviation: this.newLangAbbrev,
        title: this.newLangTitle
      });

      const response = await this.$store.dispatch("Settings/update", [
        "languages",
        {
          languages: [...languages]
        }
      ]);

      if (response) {
        await this.$store.dispatch("Settings/list");
        this.newLangTitle = "";
        this.newLangAbbrev = "";
      }
    },

    async remove(index) {
      let languages = [...this.languages];
      languages.splice(index, 1);

      const response = await this.$store.dispatch("Settings/update", [
        "languages",
        {
          languages: [...languages]
        }
      ]);

      if (response) {
        await this.$store.dispatch("Settings/list");
        this.newLangTitle = "";
        this.newLangAbbrev = "";
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
